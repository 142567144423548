<template>
  <EventsList />
</template>
<script>
import { mapGetters } from "vuex";
import EventsList from "./events/EventsList.vue";
export default {
  name: "LandingView",
  computed: {
    ...mapGetters({
      session: "session",
    }),
  },
  components: { EventsList },
};
</script>
